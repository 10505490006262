import VueChartJs from "vue-chartjs";
import Vue from "vue";

const getChart = (type) => {
  switch (type) {
    case 'LineChart':
      return VueChartJs.Line
    case 'BarChart':
      return VueChartJs.Bar
    case 'PieChart':
      return VueChartJs.Pie
    case 'DoughnutChart':
    case 'DonutChart':
      return VueChartJs.Doughnut
    default:
      throw new Error('wrong chart type')
  }
}

export default (type) => {
  return Vue.component(type, {
    extends: getChart(type),
    name: type,
    props: {
      chartdata: {
        type: Object,
        default: null,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    mounted() {
      this.renderChart(this.chartdata, this.options);
    },
  });
};