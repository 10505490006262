<template>
  <v-card class="pa-4 pt-2">
    <v-subheader>Nº Impagados por canal</v-subheader>
    <v-divider class="mb-4" />
    <donut-chart
      v-if="value !== null"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
      }"
      :chartdata="value"
    />
  </v-card>
</template>

<script>
import Chart from "@/components/charts/index.js";

export default {
  components: {
    DonutChart: Chart("DonutChart"),
  },
  props: {
    value: { type: Object, optional: false },
  },
};
</script>

<style>
</style>